import React, { useEffect, useState } from 'react';
import { LineContext } from 'components/context';
import AppointmentsCreatePage from 'pages/appointments/create';
import { getVendor, isOpenApi } from 'src/services/utils';
import { setupLiff } from 'src/lib/liff';
import {
  fetchAndSaveAuthToken,
  fetchOpenApiAuthToken,
} from 'services/user.service';
import { setAuth } from 'src/services/utils';
import HisBookingCreatePage from 'pages/hisBooking/create';
import { getOpenApiFeatureToggles } from 'src/services/client.service';
import initPages from 'src/lib/pages';
import Loading from 'src/components/1TalkDesign/Loading';
import styled from 'styled-components';

const LoadingWrapper = styled.div`
  padding-top: 50px;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100%;
`;

export default function Home(props) {
  const [message, setMessage] = useState({});
  const [token, setToken] = useState();
  const [vendor, setVendor] = useState();
  const [isLoadingInit, setIsLoadingInit] = useState(true);
  const [hisBookingIframeUrl, setHisBookingIframeUrl] = useState('');
  // #NOTES: 用 iframe url 是不是空字串來判斷是不是 HIS booking flow
  const isHisBookingFlow = hisBookingIframeUrl !== '';

  const renderAppointmentsCreatePage = () => {
    if (isLoadingInit) {
      return (
        <LoadingWrapper>
          <Loading color="rgb(0, 109, 177)"></Loading>
        </LoadingWrapper>
      );
    }

    if (isHisBookingFlow) {
      return (
        <HisBookingCreatePage
          {...props}
          {...message}
          vendor={vendor}
          hisBookingIframeUrl={hisBookingIframeUrl}
        />
      );
    }

    return <AppointmentsCreatePage {...props} {...message} vendor={vendor} />;
  };

  useEffect(() => {
    setupLiff()
      .then(async (res) => {
        setMessage(res);
        const { userId, liffId } = res;
        const t = await fetchAndSaveAuthToken({ userId, liffId });
        setAuth({ token: t, liffId, userId });
        setVendor(getVendor(t));
        setToken(t);
      })
      .catch(async (err) => {
        // @NOTE: Debug use
        // setMessage(err);
        // const userId = 'U591e139bbc06a309d6d025cf265346f1';
        // const liffId = '2001681382-PxdGn11V';
        // const t = await fetchAndSaveAuthToken({ userId, liffId });
        // setAuth({ token: t, liffId, userId });
        // setVendor(getVendor(t));
        // setToken(t);
        console.error(err);
      });
  }, []);

  useEffect(() => {
    const init = async () => {
      if (!token) {
        return;
      }
      try {
        let openApiToken = null;
        if (!isOpenApi()) {
          const { liffId, userId } = initPages();
          openApiToken = await fetchOpenApiAuthToken({ userId, liffId });
        }
        const result = await getOpenApiFeatureToggles({ openApiToken });
        setHisBookingIframeUrl(result.NewLiffUrl);
      } finally {
        setIsLoadingInit(false);
      }
    };

    init();
  }, [token]);

  if (token) {
    return (
      <LineContext.Provider value={message}>
        {renderAppointmentsCreatePage()}
      </LineContext.Provider>
    );
  }
  return null;
}
